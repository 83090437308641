import React from "react";
import { Pie } from "react-chartjs-2";
import { Box, Heading } from "@chakra-ui/react";

const Chart10Categories = () => {
  const chartData = {
    labels: ["Store 1", "Store 2", "Store 3", "Store 4", "Store 5", "Store 6", "Store 7", "Store 8", "Store 9", "Store 10"],
    datasets: [
      {
        label: "R-Value",
        data: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40", "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        labels: {
          generateLabels: (chart) => {
            const data = chart.data;
            return data.labels.map((label, index) => {
              const dataset = data.datasets[0];
              const value = dataset.data[index];
              const backgroundColor = dataset.backgroundColor[index];
              return {
                text: `${label}: ${value}`,
                fillStyle: backgroundColor,
                hidden: isNaN(dataset.data[index]),
                lineCap: 'butt',
                lineDash: [],
                lineDashOffset: 0,
                lineJoin: 'miter',
                lineWidth: 1,
                strokeStyle: backgroundColor,
                pointStyle: 'circle',
                rotation: 0
              };
            });
          }
        }
      },
      datalabels: {
        display: false
      }
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10
      }
    },
    events: ['mousemove'],
    onHover: (event, chartElement) => {
      const chart = chartElement.length ? chartElement[0].element.$context.chart : null;
      if (chart) {
        const datasetIndex = chartElement[0].datasetIndex;
        const index = chartElement[0].index;
  
        chart.legend.legendItems.forEach((legendItem, i) => {
          if (i === index) {
            legendItem.fontStyle = 'bold';
          } else {
            legendItem.fontStyle = 'normal';
          }
        });
        chart.update();
      }
    }
  };
  

  return (
    <Box width="400px" height="400px" boxShadow="md" p={2}>
      <Heading size="md" mb={4}>
        Top 10 Categories
      </Heading>
      <Pie data={chartData} options={options} />
    </Box>
  );
};

export default Chart10Categories;
