import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Add this import
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDXFkFAqhTy_muxoUQ7p1DdFGHQrEm0RHY",
  authDomain: "spearbi-d6469.firebaseapp.com",
  projectId: "spearbi-d6469",
  storageBucket: "spearbi-d6469.appspot.com",
  messagingSenderId: "998472541157",
  appId: "1:998472541157:web:ac64d654da1da21954c50c",
  measurementId: "G-TNFEJS3YTN",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const auth = getAuth(app); // Initialize Firebase Authentication

export { app, analytics, storage, auth };
