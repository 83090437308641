import React, { useState } from "react";
//import axios from "axios";
import { Box,  Heading,useTheme,  } from "@chakra-ui/react";
import { useSelectionContext } from "../../wrappers/SelectionContext";



const SuperAdmin = () => {
  const theme = useTheme();
  const { selectedRetailer, selectedStore,selectedDealer } = useSelectionContext();
  
//   const tabData = [
//     {
//       label: "Capture",
//       component: <Summary warehouse={selectedWarehouse} supplier={selectedSupplier} retailer={selectedRetailer} />,
//     },
//     {
//       label: "Imports",
//       component: <Import warehouse={selectedWarehouse} supplier={selectedSupplier} retailer={selectedRetailer} />,
//     },
//   ];

  return (
    <Box mt={2}>
      <Heading>Store Profile</Heading>
    </Box>
  );
};

export default SuperAdmin;
