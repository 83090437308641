import React from 'react';
import ChakraWrapper from './wrappers/ChakraWrapper'; 
import Layout from './_layout';
import { AuthProvider } from './wrappers/UseAuthentication';
import { SelectionProvider } from './wrappers/SelectionContext';
import { UserAccessProvider } from './wrappers/UserAccessContext';

function App() {
  return (
    <ChakraWrapper>
      <SelectionProvider>
      <UserAccessProvider>
          <AuthProvider>
          <Layout />
          </AuthProvider>
        </UserAccessProvider>
      </SelectionProvider>
    </ChakraWrapper>
  );
}

export default App;