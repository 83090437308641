import React, { useState, useEffect } from "react";
import { Routes, Route, NavLink, useLocation, Navigate } from "react-router-dom";
import { FaRegListAlt, FaWarehouse, FaFileInvoiceDollar, FaRegFileAlt, FaUserCog, FaSignOutAlt, FaSignInAlt } from "react-icons/fa";
import { Flex, Box, Text, Button, Image, useTheme, Link, Divider } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import Signin from "./pages/login/Signin";

import Dashboard from "./pages/dashboard/Dashboard";
import Forcasting from "./pages/forcasting/Forcasting";
import StoreProfile from "./pages/storeProfile/StoreProfile";
import OrganisationAdmin from "./pages/organisationAdmin/OrganisationAdmin";
import SuperAdmin from "./pages/superAdmin/SuperAdmin";

import { useAuthentication } from "./wrappers/UseAuthentication";
import { useSelectionContext } from "./wrappers/SelectionContext";
import { useUserContext } from "./wrappers/UserAccessContext";

import RetailerNavigation from "./navigations/RetailerNavigation";
import RegionNavigation from "./navigations/RegionNavigation";

import StoreNavigation from "./navigations/StoreNavigation";
import DealerNavigation from "./navigations/DealerNavigation";

import OrganisationNavigation from "./navigations/OrganisationNavigation";

// import SupplierLogo from "./components/SupplierLogo";

const Layout = () => {
  const { isAuthenticated, signOut } = useAuthentication();
  const { retailers, regions, loading } = useSelectionContext();
  const { user_page_access, loading: pageLoading } = useUserContext();
  const theme = useTheme();
  const location = useLocation();

  const linkStyles = {
    marginRight: "20px",
    textDecoration: "none",
    color: "black",
    _hover: {
      color: "red",
      textDecoration: "none",
    },
  };

  const getLinkStyles = (path) => ({
    color: location.pathname === path ? "white" : "black",
    _hover: {
      color: theme.colors.secondary2,
    },
  });

  const [adminImage, setAdminImage] = useState("/xpressicon.jpg");
  useEffect(() => {
    const userid = localStorage.getItem("userid");
    if (userid == 5 || userid == "5") {
      //setAdminImage("/DAWN3.jpg");
      //setAdminImage('/spearicon.jpg');
    }
  }, []);

  return (
    <Flex minHeight="100vh" overflow="hidden">
      <Flex as="nav" direction="column" bgGradient="linear(to-b, #C25E2C,#C25E2C)" color="white" width="150px" height="100vh" position="fixed" borderRadius="md">
        {isAuthenticated && !pageLoading && user_page_access.length > 0 && (
          <>
            {/* {stores.length > 0 && <StoreNavigation />} */}
            <StoreNavigation />
            <DealerNavigation />

            {/* {user_page_access.some((page) => page.value === "SuperAdmin") && <OrganisationNavigation />} */}

            {user_page_access.some((page) => page.value === "Dashboard") && (
              <Link as={NavLink} to="/dashboard" sx={{ ...linkStyles, ...getLinkStyles("/dashboard") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4} mt={4}>
                  <FaRegListAlt />
                  <Text ml={2}>Dashboard</Text>
                </Box>
              </Link>
            )}
            {user_page_access.some((page) => page.value === "Forcasting") && (
              <Link as={NavLink} to="/forcasting" sx={{ ...linkStyles, ...getLinkStyles("/forcasting") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4} mt={4}>
                  <FaWarehouse />
                  <Text ml={2}>Forcasting</Text>
                </Box>
              </Link>
            )}
            {user_page_access.some((page) => page.value === "StoreProfile") && (
              <Link as={NavLink} to="/storeProfile" sx={{ ...linkStyles, ...getLinkStyles("/storeProfile") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4} mt={4}>
                  <FaFileInvoiceDollar />
                  <Text ml={2}>StoreProfile</Text>
                </Box>
              </Link>
            )}

            <Divider mb={4} />
            {user_page_access.some((page) => page.value === "OrganisationAdmin") && (
              <Link as={NavLink} to="/organisationAdmin" sx={{ ...linkStyles, ...getLinkStyles("/organisationAdmin") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaUserCog />
                  <Text ml={2}>Organisation Admin</Text>
                </Box>
              </Link>
            )}
            {user_page_access.some((page) => page.value === "SuperAdmin") && (
              <Link as={NavLink} to="/superAdmin" sx={{ ...linkStyles, ...getLinkStyles("/superAdmin") }}>
                <Box display="flex" alignItems="center" mb={4} pl={4}>
                  <FaUserCog />
                  <Text ml={2}>Super Admin</Text>
                </Box>
              </Link>
            )}

            {/* <Divider mb={4} /> */}

            <Button leftIcon={<FaSignOutAlt />} onClick={signOut} variant="outline" colorScheme="alphaWhite" position="fixed" bottom="10px" left="17px" zIndex="1000">
              Logout
            </Button>
          </>
        )}
        {!isAuthenticated && (
          // <NavLink to="/signin" style={{ marginRight: "20px" }}>
          //   <Text>Sign In</Text>
          // </NavLink>
          <>
            <Link as={NavLink} to="/signin" sx={{ ...linkStyles, ...getLinkStyles("/signin") }}>
              <Box display="flex" alignItems="center" mb={4} pl={4} mt={4}>
                <FaSignInAlt />
                <Text ml={2}>Sign In</Text>
              </Box>
            </Link>
            {/* <Link as={NavLink} to="/contact-us" sx={{ ...linkStyles, ...getLinkStyles("/contact-us") }}>
              <Box display="flex" alignItems="center" mb={4} pl={4}>
                <FaEnvelope />
                <Text ml={2}>Contact Us</Text>
              </Box>
            </Link>
            <Link as={NavLink} to="/about-us" sx={{ ...linkStyles, ...getLinkStyles("/about-us") }}>
              <Box display="flex" alignItems="center" mb={4} pl={4}>
                <FaInfoCircle />
                <Text ml={2}>About Us</Text>
              </Box>
            </Link> */}
          </>
        )}
      </Flex>

      <Flex direction="column" flex="1" overflow="hidden" ml="150px">
        <Box p={4}>
          {isAuthenticated && !loading && retailers.length > 0 && <RetailerNavigation />}
          {isAuthenticated && !loading && (
            <Box width="100%">
              <RegionNavigation />
            </Box>
          )}
          <Routes>
            <Route path="/signin" element={<Signin />} />
            {isAuthenticated && (
              <>
                <Route
                  path="/dashboard"
                  element={
                    <>
                      <Helmet>
                        <title>Dashboard - Xpress</title>
                        <meta name="description" content="Dashboard XXX" />
                      </Helmet>
                      <Dashboard />
                    </>
                  }
                />
                <Route
                  path="/forcasting"
                  element={
                    <>
                      <Helmet>
                        <title>Forcasting - Xpress</title>
                        <meta name="description" content="Analyze your Forcasting data comprehensively with Xpress's Forcasting system tools." />
                      </Helmet>
                      <Forcasting />
                    </>
                  }
                />
                <Route
                  path="/storeProfile"
                  element={
                    <>
                      <Helmet>
                        <title>StoreProfile - Xpress</title>
                        <meta name="description" content="StoreProfile " />
                      </Helmet>
                      <StoreProfile />
                    </>
                  }
                />
                <Route
                  path="/organisationAdmin"
                  element={
                    <>
                      <Helmet>
                        {" "}
                        <title>Organisation Admin - Xpress</title> <meta name="description" content="Organisation Admin" />{" "}
                      </Helmet>
                      <OrganisationAdmin />
                    </>
                  }
                />
                <Route
                  path="/superAdmin"
                  element={
                    <>
                      <Helmet>
                        <title>Super Admin - Xpress</title> <meta name="description" content="Super Admin" />{" "}
                      </Helmet>
                      <SuperAdmin />
                    </>
                  }
                />
              </>
            )}
            <Route path="*" element={<Navigate to="/signin" replace />} />
            <Route path="/" element={<Signin />} />
          </Routes>
        </Box>
      </Flex>
      <Box display="flex" alignItems="center" maxHeight="100" maxWidth="100" bg="white" position="absolute" top="5px" right="5px" zIndex="1000">
        <Image src={adminImage} alt="Logo" objectFit="cover" borderRadius="xl" />
      </Box>
    </Flex>
  );
};

export default Layout;
