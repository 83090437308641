import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react';

const TablePromotion = () => {
  return (
    <Box overflowX="auto" maxH="500px">
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th>Article #</Th>
            <Th>Article Description</Th>
            <Th>Category</Th>
            <Th>Sub-Category</Th>
            <Th>Case Pack</Th>
            <Th>Cost Price (List)</Th>
            <Th>RSP</Th>
            <Th>Margin</Th>
            <Th colSpan={2}>SOH at existing value (off promo)</Th>
            <Th colSpan={2}>DROS off promo</Th>
            <Th>Promo Cost</Th>
            <Th>Promo RSP</Th>
            <Th>Promo Margin</Th>
            <Th colSpan={3}>Targeted Promo Growth based on Promo RSP</Th>
            <Th>% Buffer stock for Promo</Th>
            <Th colSpan={3}>Suggested Promo Order (units/cases)</Th>
            <Th colSpan={3}>Required Sales to Achieve Breakeven Margin</Th>
          </Tr>
          <Tr>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Margin</Th>
            <Th></Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Margin</Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Margin</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>1</Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default TablePromotion;
