import React from "react";
import { Box, Heading, SimpleGrid, useTheme } from "@chakra-ui/react";
import { useSelectionContext } from "../../wrappers/SelectionContext";

import TablePromotion from "./components/TablePromotion";
import TablePerformance from "./components/TablePerformance";
import Table10Articles from "./components/Table10Articles";
import Table10Combos from "./components/Table10Combos";
import Chart10Articles from "./components/Chart10Articles";
import Chart10Combos from "./components/Chart10Combos";

const Forcasting = () => {
  const theme = useTheme();
  const { selectedRetailer, selectedStore, selectedDealer, selectedRgion } = useSelectionContext();

  return (
    <Box mt={2}>
      <Heading>Forcasting</Heading>
      <Box mt={4} overflowY="auto" maxH="500px">
        <TablePromotion />
      </Box>
      <Box mt={4} overflowY="auto" maxH="500px">
        <TablePerformance />
      </Box>
      <SimpleGrid columns={2} spacing={4} mt={4}>
        <Table10Articles />
        <Table10Combos />
      </SimpleGrid>
      <SimpleGrid columns={4} spacing={1} mt={4}>
        <Chart10Articles />
        <Chart10Combos />
      </SimpleGrid>
    </Box>
  );
};

export default Forcasting;
