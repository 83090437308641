import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react';

const TablePerformanceCategories = () => {
  return (
    <Box overflowX="auto" boxShadow="md" p={2}>
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th colSpan={4}>Bottom Performing Categories</Th>
          </Tr>
          <Tr>
            <Th>Name</Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Margin</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>1</Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default TablePerformanceCategories;
