import React, { useEffect } from "react";
import { Box, Flex, Tabs, TabList, Tab, Image, useTheme } from "@chakra-ui/react";
import { useSelectionContext } from "../wrappers/SelectionContext";

const RegionNavigation = () => {
  const theme = useTheme();
  const { selectedRegion, setSelectedRegion, regions } = useSelectionContext();

  useEffect(() => {
    console.log("Regions updated:", regions); // Log the dealers array to debug
  }, [regions]);

  const handleRegionChange = (region) => {
    if (regions.some((r) => r.value === region)) {
      setSelectedRegion(region);
    }
  };

  return (
    <Box width="100%" mt={10}>
      <Flex justifyContent="center">
        <Tabs variant="unstyled" isFitted>
          <TabList>
            {regions.map((region) => (
              <Tab
                key={region.value}
                onClick={() => handleRegionChange(region.value)}
                _selected={{ color: theme.colors.primary1, border: `2px solid ${theme.colors.primary1}`, bg: "white" }}
                _hover={{ color: theme.colors.secondary1, border: `2px solid ${theme.colors.primary1}` }}
                bg={selectedRegion === region.value ? "black" : theme.colors.gray[100]}
                color={selectedRegion === region.value ? "black" : "black"}
                border={"2px solid white"} //
                p={2}
                flexShrink={0}
                flexGrow={1}
                height="50px"
                width="150px"
                borderRadius="md"
                marginRight="4"
              >
                <Flex alignItems="center" height="100%">
                  <Image boxSize="2rem" src={region.logo} alt={region.text} mr="12px" borderRadius="full" />
                  <Flex justifyContent="center" flex="1">
                    {region.text}
                  </Flex>
                </Flex>
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Flex>
    </Box>
  );
};

export default RegionNavigation;
