import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react';

const TableStoresComparison = () => {
  return (
    <Box overflowX="auto" maxH="500px">
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th>Store Name</Th>
            <Th>Store Customer Profile (Urban, Transient, Industrial, Rural)</Th>
            <Th>Category</Th>
            <Th>Sub-Category</Th>
            <Th>Article #</Th>
            <Th>Article Description</Th>
            <Th># of SKUs</Th>
            <Th>Category Sales Contribution to Store %</Th>
            <Th>Category Margin Contribution to Store %</Th>
            <Th>YOY Store Turnover</Th>
            <Th>YOY Store Margin</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>1</Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default TableStoresComparison;
