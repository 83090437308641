import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FormControl, FormLabel, Input, Button, Stack, useToast, useTheme, InputGroup, InputRightElement, IconButton, Checkbox } from "@chakra-ui/react";
import { useAuthentication } from "../../wrappers/UseAuthentication";
import { useSelectionContext } from "../../wrappers/SelectionContext";
import { useUserContext } from "../../wrappers/UserAccessContext";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const SignIn = () => {
  //const apiUrl = process.env.REACT_APP_API_URL;
  const toast = useToast();
  const [username, setUsername] = useState("");
  const [userpass, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [staySignedIn, setStaySignedIn] = useState(localStorage.getItem("staySignedIn") === "true");

  const { signIn } = useAuthentication();

  const { fetch_selectionContext, reset_selectionContext } = useSelectionContext();//: resetSupplierRetailerContext
  const { fetch_userAccess, reset_userContext } = useUserContext();

  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const autoSignIn = async () => {
      const ls_staySignedIn = localStorage.getItem("staySignedIn") === "true";
      if (ls_staySignedIn) {
        const ls_username = localStorage.getItem("username");
        const ls_password = localStorage.getItem("userpass");
        if (ls_username && ls_password) {
          setUsername(ls_username);
          setPassword(ls_password);
          await signin(ls_username, ls_password);
        }
      }
    };
    autoSignIn();
  }, []);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setFormIsSubmitting(true);

    reset_selectionContext();
    reset_userContext();

    await signin(username, userpass);
  };

  const signin = async (username, userpass) => {
    try {
      //const response = await axios.post(`${process.env.REACT_APP_API_URL}/login/login`, { username, userpass });
      setFormIsSubmitting(false);
      //const { status, title, message, data } = response.data;
      //const response = { status: 201, data: { data: [{ userid: "1", fname: "michael" }] } };
      const { status, title, message, data } = {
        status:"success",
        title:"Success",
        message:`Welcome ${username}`,
        data:{userid:"1234-5678",isadmin:true,isorganisation:false,isdealer:false}};
      toast({
        title: title,
        description: message,
        status: status,
        duration: 1500,
        isClosable: true,
      });

      if (Object.keys(data).length) {
        const userid = data.userid;

        localStorage.setItem("userid", userid);


        const isadmin = data.isadmin;
        const isorganisation = data.isorganisation;
        const isdealer=data.isdealer;


        await fetch_selectionContext({ userid, });
        await fetch_userAccess({ userid, isadmin, isorganisation, isdealer });

        signIn();
        navigate("/dashboard");
      }
    } catch (error) {
      setFormIsSubmitting(false);
      const errMsg = error.response?.data?.data?.error || "Technical Error Occurred";
      toast({
        title: "Sign in Failed!",
        description: errMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleStaySignedIn = (checkboxValue) => {
    setStaySignedIn(checkboxValue);
    localStorage.setItem("staySignedIn", checkboxValue);
  };

  return (
    <FormControl maxW="400px" mx="auto" mt={8} p="4" borderWidth="1px" borderRadius="lg">
      <form onSubmit={handleSignIn}>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Username</FormLabel>
            <Input type="text" placeholder="Enter your username" value={username} onChange={(e) => setUsername(e.target.value)} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input type={showPassword ? "text" : "password"} placeholder="Enter your password" value={userpass} onChange={(e) => setPassword(e.target.value)} />
              <InputRightElement>
                <IconButton colorScheme="customRed" aria-label={showPassword ? "Hide userpass" : "Show userpass"} icon={showPassword ? <ViewIcon /> : <ViewOffIcon />} onClick={() => setShowPassword(!showPassword)} variant="ghost" />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl>
            <Checkbox isChecked={staySignedIn} onChange={(e) => handleStaySignedIn(e.target.checked)} colorScheme="customRed">
              Stay signed in
            </Checkbox>
          </FormControl>
          <Button type="submit" colorScheme="customRed" variant="outline" isLoading={formIsSubmitting} loadingText="Signing In">
            Sign in
          </Button>
        </Stack>
      </form>
    </FormControl>
  );
};

export default SignIn;
