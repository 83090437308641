import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react';

const TableSales = () => {
  return (
    <Box >
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th>Article #</Th>
            <Th>Category</Th>
            <Th>Sub-Category</Th>
            <Th colSpan={2}>Previous 4 weeks (by week - from Mon-Sun=1 week)</Th>
            <Th colSpan={2}>SOH in Store / COS</Th>
            <Th colSpan={2}>Suggested Ideal SOH</Th>
            <Th colSpan={2}>Sales Contribution %</Th>
            <Th colSpan={2}>Margin Category Actual %</Th>
            <Th colSpan={2}>Margin Category Suggested %</Th>
            <Th colSpan={3}>YOY</Th>
            <Th colSpan={3}>Half YOY</Th>
            <Th colSpan={3}>3-months YOY</Th>
          </Tr>
          <Tr>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Margin</Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Margin</Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Margin</Th>
          </Tr>
        </Thead>
        <Tbody>
        <Tr>
            <Td>ABC</Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default TableSales;
