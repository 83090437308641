import React, { useState, useEffect } from "react";
import { Box, Menu, MenuButton, MenuList, MenuItem, Button, useTheme, Image } from "@chakra-ui/react";

import { useSelectionContext } from "../wrappers/SelectionContext";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { FaStore } from "react-icons/fa";

const StoreNavigation = () => {
  const theme = useTheme();
  const { selectedStore, setSelectedStore, stores } = useSelectionContext();
  const [logos, setLogos] = useState({});

  // useEffect(() => {
  //   const fetchLogos = async () => {
  //     const existingLogos = {...logos};
  //     const newLogosNeeded = {};

  //     for (const retailer of stores) {
  //       if (!existingLogos[retailer.value]) {
  //         newLogosNeeded[retailer.value] = true;
  //       }
  //     }

  //     if (Object.keys(newLogosNeeded).length > 0) {
  //       for (const retailerKey in newLogosNeeded) {
  //         try {
  //           const logoRef = ref(storage, `retailer_logos/${retailerKey}`);
  //           const url = await getDownloadURL(logoRef);
  //           existingLogos[retailerKey] = url;
  //         } catch (error) {
  //           console.error("Error fetching logo:", error);
  //           existingLogos[retailerKey] = "/default_logo.jpg"; // Adjust the path to your default logo
  //         }
  //       }
  //       setLogos(existingLogos);
  //     }
  //   };

  //   fetchLogos();
  // }, [stores]);

  const handleStoreChange = (store) => {
    if (stores.some((r) => r.value === store)) {
      setSelectedStore(store);
    }
  };

  return (
    <Box display="flex" alignItems="center" m={1} width="calc(100% - 8px)" mx="4px">
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg={"white"} _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }} color="black" maxWidth="100%" minWidth="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" px={1} justifyContent="space-between">
          {selectedStore}
        </MenuButton>
        <MenuList bg={"white"} color="black" maxWidth="100%" maxHeight="500px" overflowY="auto">
          {stores.map((store) => (
            <MenuItem
              key={store.value}
              onClick={() => handleStoreChange(store.value)}
              bg="white"
              _hover={{ bg: `${theme.colors.secondary1} !important`, color: "white !important" }} //
              color="black"
              maxWidth="100%"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              minH="40px"
            >
              <FaStore size="2rem" style={{ marginRight: "12px" }} />
              <span>{store.text}</span>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default StoreNavigation;
