// ChakraWrapper.js
import React from "react";
import { ChakraProvider, extendTheme, Spinner } from "@chakra-ui/react";
import { FaPlus, FaFileUpload, FaSave } from "react-icons/fa";

const customTheme = extendTheme({
  components: {
    Input: {
      variants: {
        outline: (props) => ({
          field: {
            borderColor: props.colorMode === "dark" ? "black" : "black",
            _hover: {
              borderColor: "gray",
            },
            _focus: {
              borderColor: "customRed.800",
              boxShadow: `0 0 0 1px ${props.theme.colors.customRed[800]}`,
            },
          },
        }),
      },
    },
    Select: {
      variants: {
        outline: (props) => ({
          field: {
            borderColor: props.colorMode === "dark" ? "black" : "black",
            _hover: {
              borderColor: "gray",
            },
            _focus: {
              borderColor: "customRed.800",
              boxShadow: `0 0 0 1px ${props.theme.colors.customRed[800]}`,
            },
          },
        }),
      },
    },
  },
  colors: {
    customRed: {
      50: "#FFEDE5", // Lightest shade
      100: "#FFDACB", // Lighter shade
      200: "#FFC7B1", // Light shade
      300: "#FFB497", // Light normal
      400: "#FFA17D", // Normal shade
      500: "#F37335", // Base color
      600: "#C25E2C", // Darker than base
      700: "#914923", // Dark shade
      800: "#60341A", // Darker shade
      900: "#301A0D"  // Darkest shade
    },
    primary1: "#C25E2C",
    primary2: "#fff",
    secondary1: "#C25E2C",
    secondary1Light: "#C25E2C",
    secondary2: "#D3CCE3",
    tertiary1: "#fceabb",
    tertiary2: "#ffe5a3",
    tertiary2Light: "rgba(255, 229, 163, 0.7)",
    white: "#FFF",
    black: "#000",

    theadButton_bg: "white",
    theadButton_text: "black",
    theadButton_border: "white",

    tbodyButton_bg: "white",
    tbodyButton_text: "black",
    tbodyButton_border: "white",

    theadButton_bg_hover: "gray",
    theadButton_text_hover: "black",
    theadButton_border_hover: "gray",

    tbodyButton_bg_hover: "gray",
    tbodyButton_text_hover: "black",
    tbodyButton_border_hover: "gray",

    gradients: {
      primary: "linear(to-r, #58b6c0, #04aec1)",
      secondary: "linear(to-r, #04aec1, #58b6c0)",
    },
  },
});
const ChakraWrapper = ({ children }) => {
  return <ChakraProvider theme={customTheme}>{children}</ChakraProvider>;
};

export default ChakraWrapper;
