import React from "react";
import { Box, Heading, useTheme } from "@chakra-ui/react";
import { useSelectionContext } from "../../wrappers/SelectionContext";

import TableStoresComparison from "./components/TableStoresComparison";

const StoreProfile = () => {
  const theme = useTheme();
  const { selectedRetailer, selectedStore, selectedDealer } = useSelectionContext();

  return (
    <Box mt={2}>
      <Heading>Store Profile</Heading>
      <Box mt={4} overflowY="auto" maxH="500px">
        <TableStoresComparison />
      </Box>
    </Box>
  );
};

export default StoreProfile;
