import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
// import { getDownloadURL, ref } from "firebase/storage";
// import { storage } from "../tools/firebase";

const SelectionContext = createContext();

export const useSelectionContext = () => useContext(SelectionContext);

export const SelectionProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  //global variables
  const [userId, setUserId] = useState(null);

  //contexts
  const [selectedRetailer, setSelectedRetailer] = useState("");
  const [retailers, setRetailers] = useState([
    { value: "PNP", text: "Pick n Pay", logo: "PNP.jpg" },
    { value: "SAS", text: "Sasol", logo: "SAS.jpg" },
    { value: "IND", text: "Independant", logo: "IND.jpg" },
  ]);

  const [selectedStore, setSelectedStore] = useState("StoreA");
  const [stores, setStores] = useState([
    { value: "StoreA", text: "Test Store A", logo: "STORE.jpg" },
    { value: "StoreB", text: "Test Store B", logo: "STORE.jpg" },
    { value: "StoreC", text: "Test Store C", logo: "STORE.jpg" },
  ]);

  const [selectedDealer, setSelectedDealer] = useState("DealerA");
  const [dealers, setDealers] = useState([
    { value: "DealerA", text: "Test Dealer A", logo: "DEALER.jpg" },
    { value: "DealerB", text: "Test Dealer B", logo: "DEALER.jpg" },
    { value: "DealerC", text: "Test Dealer C", logo: "DEALER.jpg" },
  ]);

  const [selectedRegion, setSelectedRegion] = useState("GTNG");
  const [regions, setRegions] = useState([
    { value: "GTNG", text: "GTNG", logo: "GTNG.jpg" },
    { value: "NR", text: "NR", logo: "NR.jpg" },
    { value: "WC", text: "WC", logo: "WC.jpg" },
    { value: "EC", text: "EC", logo: "EC.jpg" },
    { value: "KZN", text: "KZN", logo: "KZN.jpg" },
  ]);

  const reset_selectionContext = () => {
    setSelectedRetailer("");
    setRetailers([]); 

    setSelectedStore("");
    setStores([]); 

    setSelectedDealer("");
    setDealers([]); 

    setSelectedRegion("");
    setRegions([]); 

    setLoading(false);
  };

  const fetch_selectionContext = async ({ userid }) => {
    if (userid == null) {
      return;
    }
    try {
      setLoading(true);
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-selectionContext`, { userid, organisationcode });
      // const { status, data, title } = response.data;
      // const { suppliers, retailers, warehouses, distributors, organisations } = data;
      // setOrganisationCode(organisationcode);
      // setRetailerCode(retailercode);
      // setSuppplierCode(supppliercode);
      //setUserId(userid);
      setUserId(userid);

      await fetch_retailers();
      await fetch_stores();
      await fetch_dealers();
    } catch (error) {
      console.error("Error fetching user access:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetch_retailers = async () => {
    if (userId == null) {
      return;
    }
    try {
      setLoading(true);
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-retailers`, { userid: userId, organisation: selectedOrganisation, source: "selectionContext" });
      // const { status, retailers, title } = response.data;
      // let retailer_temp = {};
      // for (let i = 0; i < retailers.length; i++) {
      //   retailer_temp[retailers[i].retailercode] = true;
      // }
      setSelectedRetailer("PNP");
      setRetailers([
        { value: "PNP", text: "Pick n Pay", logo: "PNP.jpg" },
        { value: "SAS", text: "Sasol", logo: "SAS.jpg" },
        { value: "IND", text: "Independant", logo: "IND.jpg" },
      ]);
    } catch (error) {
      console.error("Error fetching user access:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetch_stores = async () => {
    if (userId == null) {
      return;
    }
    try {
      setLoading(true);
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-retailers`, { userid: userId, organisation: selectedOrganisation, source: "selectionContext" });
      // const { status, retailers, title } = response.data;
      // let retailer_temp = {};
      // for (let i = 0; i < retailers.length; i++) {
      //   retailer_temp[retailers[i].retailercode] = true;
      // }
      setSelectedStore("StoreA");
      setStores([
        { value: "StoreA", text: "Test Store A", logo: "STORE.jpg" },
        { value: "StoreB", text: "Test Store B", logo: "STORE.jpg" },
        { value: "StoreC", text: "Test Store C", logo: "STORE.jpg" },
      ]);
    } catch (error) {
      console.error("Error fetching user access:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetch_dealers = async () => {
    if (userId == null) {
      return;
    }
    try {
      setLoading(true);
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-retailers`, { userid: userId, organisation: selectedOrganisation, source: "selectionContext" });
      // const { status, retailers, title } = response.data;
      // let retailer_temp = {};
      // for (let i = 0; i < retailers.length; i++) {
      //   retailer_temp[retailers[i].retailercode] = true;
      // }
      setSelectedDealer("DealerA");
      setDealers([
        { value: "DealerA", text: "Test Dealer A", logo: "DEALER.jpg" },
        { value: "DealerB", text: "Test Dealer B", logo: "DEALER.jpg" },
        { value: "DealerC", text: "Test Dealer C", logo: "DEALER.jpg" },
      ]);
    } catch (error) {
      console.error("Error fetching user access:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetch_regions = async () => {
    if (userId == null) {
      return;
    }
    try {
      setLoading(true);
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-retailers`, { userid: userId, organisation: selectedOrganisation, source: "selectionContext" });
      // const { status, retailers, title } = response.data;
      // let retailer_temp = {};
      // for (let i = 0; i < retailers.length; i++) {
      //   retailer_temp[retailers[i].retailercode] = true;
      // }
      setSelectedRegion("GTNG");
      setRegions([
        { value: "GTNG", text: "GTNG", logo: "GTNG.jpg" },
        { value: "NR", text: "NR", logo: "NR.jpg" },
        { value: "WC", text: "WC", logo: "WC.jpg" },
        { value: "EC", text: "EC", logo: "EC.jpg" },
        { value: "KZN", text: "KZN", logo: "KZN.jpg" },
      ]);
    } catch (error) {
      console.error("Error fetching user access:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch_retailers();
  }, [selectedStore]); //reset retailers 

  useEffect(() => {
    fetch_stores();
  }, [selectedRetailer,selectedDealer,selectedRegion]); //reset stores 

  useEffect(() => {
    fetch_dealers();
  }, [selectedStore,selectedRegion]); //reset dealers 

  useEffect(()=>{
    fetch_regions();
  },[selectedStore])

  return (
    <SelectionContext.Provider
      value={{
        loading,
        fetch_selectionContext,
        reset_selectionContext,
        userId,

        selectedRetailer,
        setSelectedRetailer,
        retailers,
        fetch_retailers,

        selectedStore,
        setSelectedStore,
        stores,
        fetch_stores,

        selectedDealer,
        setSelectedDealer,
        dealers,
        fetch_dealers,

        selectedRegion,
        setSelectedRegion,
        regions,
        fetch_regions,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};
