import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react';

const Table10Combos = () => {
  return (
    <Box overflowX="auto">
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th colSpan={5}>Top 10 Performing Combos</Th>
          </Tr>
          <Tr>
            <Th>Name</Th>
            <Th>Units</Th>
            <Th>R-value</Th>
            <Th>Margin</Th>
            <Th>Contribution % to Promo</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>1</Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td></Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default Table10Combos;
