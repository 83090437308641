import React, { useState } from "react";
//import axios from "axios";
import { Box, Heading, SimpleGrid, useTheme } from "@chakra-ui/react";
import { useSelectionContext } from "../../wrappers/SelectionContext";

import TableSales from "./components/TableSales";

import Table10Categories from "./components/Table10Categories";
import Table10SubCategories from "./components/Table10SubCategories";
import Table10Articles from "./components/Table10Articles";
import Table10OverstockedArticles from "./components/Table10OverstockedArticles";

import TablePerformanceCategories from "./components/TablePerformanceCategories";
import TablePerformanceSubCategories from "./components/TablePerformanceSubCategories";
import TablePerformanceArticles from "./components/TablePerformanceArticles";

import Chart10Categories from "./components/Chart10Categories";
import Chart10SubCategories from "./components/Chart10SubCategories";
import ChartPerformanceCategories from "./components/ChartPerformanceCategories";
import ChartPerformanceSubCategories from "./components/ChartPerformanceSubCategories";
import Chart10Articles from "./components/Chart10Articles";
import Chart10OverstockedArticles from "./components/Chart10OverstockedArticles";
import Chart10PerformanceArticles from "./components/Chart10PerformanceArticles";

const Dashboard = () => {
  const theme = useTheme();
  const { selectedRetailer, selectedStore, selectedDealer, selectedRegion } = useSelectionContext();

  //   const tabData = [
  //     {
  //       label: "Capture",
  //       component: <Summary warehouse={selectedWarehouse} supplier={selectedSupplier} retailer={selectedRetailer} />,
  //     },
  //     {
  //       label: "Imports",
  //       component: <Import warehouse={selectedWarehouse} supplier={selectedSupplier} retailer={selectedRetailer} />,
  //     },
  //   ];

  return (
    <Box mt={2}>
      <Heading>Dashboard</Heading>
      <Box mt={4} overflowY="auto" maxH="500px" boxShadow="md" p={2}>
        <TableSales />
      </Box>
      <SimpleGrid columns={4} spacing={4} mt={4}>
        <Table10Categories />
        <Table10SubCategories />
        <Table10Articles />
        <Table10OverstockedArticles />
      </SimpleGrid>
      <SimpleGrid columns={3} spacing={4} mt={4}>
        <TablePerformanceCategories />
        <TablePerformanceSubCategories />
        <TablePerformanceArticles />
      </SimpleGrid>

      <SimpleGrid columns={4} spacing={4} mt={4}>
        <Chart10Categories />
        <Chart10SubCategories />
        <Chart10Articles />
        <Chart10OverstockedArticles />
      </SimpleGrid>

      <SimpleGrid columns={4} spacing={3} mt={4}>
        <ChartPerformanceCategories />
        <ChartPerformanceSubCategories />
        <Chart10PerformanceArticles />
        <Box />
      </SimpleGrid>
    </Box>
  );
};

export default Dashboard;
