import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelectionContext } from "./SelectionContext";
import { useUserContext } from "./UserAccessContext";

const AuthContext = createContext();

export const useAuthentication = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const { reset_selectionContext} = useSelectionContext();
  const { reset_userContext } = useUserContext();
  const signIn = () => {
    setIsAuthenticated(true);
    // reset_selectionContext();//@mike
    // reset_userContext();//@mike
  };

  const signOut = () => {
    setIsAuthenticated(false);
    reset_selectionContext();
    reset_userContext();
    localStorage.clear();
    navigate("/signin");
  };

  return <AuthContext.Provider value={{ isAuthenticated, signIn, signOut }}>{children}</AuthContext.Provider>;
};
