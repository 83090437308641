import React, { createContext, useContext, useState } from "react";
import axios from "axios";

const UserAccessContext = createContext();

export const useUserContext = () => useContext(UserAccessContext);
//useUserContext
export const UserAccessProvider = ({ children }) => {
  const [user_page_access, setUser_page_access] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetch_userAccess = async ({ userid, isadmin, isorganisation, isdealer }) => {
    setLoading(true);
    try {
      if (isadmin) {
        setUser_page_access([{ value: "SuperAdmin" }, { value: "OrganisationAdmin" }, { value: "Dashboard" }, { value: "Forcasting" }, { value: "StoreProfile" }]);
      } else if (isorganisation) {
        setUser_page_access([{ value: "OrganisationAdmin" }, { value: "Dashboard" }, { value: "Forcasting" }, { value: "StoreProfile" }]);
      } else if (isdealer) {
        setUser_page_access([{ value: "Dashboard" }, { value: "Forcasting" }, { value: "StoreProfile" }]);
      } else {
        alert("page access error");
      }
    } catch (error) {
      console.error("Error fetching page access", error);
    } finally {
      setLoading(false);
    }
  };

  const reset_userContext = () => {
    setUser_page_access([]);
    setLoading(false);
  };

  return <UserAccessContext.Provider value={{ user_page_access, fetch_userAccess, loading, reset_userContext }}>{children}</UserAccessContext.Provider>;
};
